import { WaveLinksProps } from './getWaveLinks';
import pickWaveLinks from './pickWaveLinks';

const getWaveUserMenuLinks = (props: WaveLinksProps) => {
  const userMenuLinks = pickWaveLinks(props)(['myProjects', 'myStream', 'myProfile', 'myPlan']);

  return userMenuLinks;
};

export default getWaveUserMenuLinks;
