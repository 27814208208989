import compose from 'lodash/fp/compose';
import pick from 'lodash/fp/pick';
import values from 'lodash/fp/values';

import getStudioLinks, { StudioLinks, StudioLinkKeys } from './getStudioLinks';

export default (studioUrl: string) =>
  compose(
    values,
    pick<StudioLinks, StudioLinkKeys>(['myProjects', 'myProfile', 'myPlan'])
  )(getStudioLinks(studioUrl));
