export const organizationData = {
  '@context': 'http://schema.org',
  '@type': 'Corporation',
  '@id': 'https://wave.video',
  name: 'Wave.video',
  description:
    'Wave.video is a video marketing platform that combines first-rate video hosting services, an online' +
    ' video editor, and an instant video landing page builder in one platform. This set of tools allows' +
    " marketers to create and repurpose videos for any marketing channel. It's a game-changer for" +
    ' businesses that aim to increase ROI from the marketing funnel using videos.',
  logo: 'https://assets.animatron.com/static/images/data/about/circle-about-us.png',
  url: 'https://wave.video',
  sameAs: [
    'https://twitter.com/wave_video',
    'https://www.linkedin.com/company/wavevideo/',
    'https://www.facebook.com/wave.video',
    'https://www.youtube.com/c/Wavevideo',
    'https://www.instagram.com/wave.video',
    'https://www.pinterest.com/wave_video/'
  ],
  address: {
    '@type': 'PostalAddress',
    streetAddress: '221 Crescent Street, Suite 401',
    addressLocality: 'Waltham',
    postalCode: 'MA 02453',
    addressCountry: 'USA'
  }
};
