import { LinkProps } from '@anm/components/header';
import mapObject from '@anm/helpers/mapObject';
import compose from 'lodash/fp/compose';
import pick from 'lodash/fp/pick';
import values from 'lodash/fp/values';

import getWaveLinks, {
  WaveLinks,
  WaveLinksProps,
  WaveLinkKeys
} from '../getWaveLinks';

export type PickLink = LinkProps & { name: string };

const pickWaveLinks = (props: WaveLinksProps) => (
  keys: WaveLinkKeys[]
): PickLink[] =>
  compose(
    values,
    mapObject((key, value) => ({ ...value, name: key })),
    pick<WaveLinks, WaveLinkKeys>(keys)
  )(getWaveLinks(props));

export default pickWaveLinks;
