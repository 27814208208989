import { LinkProps } from '@anm/components/header';

import commonLinks from '../commonLinks';

export type StudioLinkKeys = keyof ReturnType<typeof getStudioLinks>;
export type StudioLinks = { [key in StudioLinkKeys]: LinkProps };

const getStudioLinks = (studioUrl: string) => ({
  ...commonLinks(studioUrl),
  termsUse: {
    title: 'Terms of Use',
    href: `${studioUrl}terms-of-service`
  },
  privacyPolicy: {
    title: 'Privacy Policy',
    href: `${studioUrl}privacy`
  },
  myProjects: {
    title: 'My Projects',
    href: `${studioUrl}home`
  },
  socialGithub: {
    title: 'github',
    href: 'https://github.com/Animatron'
  },
  socialTwitter: {
    title: 'twitter',
    href: 'https://twitter.com/animatron'
  },
  socialFacebook: {
    title: 'facebook',
    href: 'https://www.facebook.com/TheAnimatron'
  },
  socialYoutube: {
    title: 'youtube',
    href: 'https://www.youtube.com/c/AnimatronHero'
  }
});

export default getStudioLinks;
