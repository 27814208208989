import { AnimatronProduct } from '@anm/api/modules/auth';
import { Size } from '@anm/api/types';
import LanguageSwitcher from '@anm/components/LanguageSwitcher';
import LangScripts from '@anm/components/LanguageSwitcher/LangScripts';
import Termly from '@anm/components/Termly';
import ReviewSnippet, { getUrlShortPath } from '@anm/components/structures/Review';
import SafeHead from '@anm/components/SafeHeaad';
import React, { FC } from 'react';

import withAppMeta, { WithAppMetaProps } from '../../HOCs/withAppMeta';
import vtConfig from '../../config';

import cleanPath from './helpers/cleanPath';

const { urls, langs } = vtConfig();
const getFaviconFileName = (product: AnimatronProduct) =>
  ({
    WAVE: 'wv-favicon.ico',
    STUDIO: 'favicon-studio.ico'
  }[product]);

export type HeadProps = WithAppMetaProps & {
  title: string;
  path?: string;
  type?: string;
  size?: Size;
  preview?: string;
  description?: string;
  includeLangLinks?: boolean;
};

const MetaHead: FC<HeadProps> = ({
  path,
  type,
  size,
  title,
  preview,
  description,
  includeLangLinks,
  appMeta: { product }
}) => {
  const cleanedPath = cleanPath(path);

  return (
    <>
      {includeLangLinks && (
        <>
          <LanguageSwitcher />
          <LangScripts {...langs} path={cleanedPath} />
        </>
      )}

      <SafeHead key="meta_head">
        <link rel="icon" type="image/x-icon" href={`${urls.cdn}static/${getFaviconFileName(product)}`} />
        <meta name="robots" content="all" />
        <meta charSet="utf-8" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <title>{title}</title>

        {cleanedPath && (
          <>
            <meta property="og:url" content={cleanedPath} />
            <link rel="canonical" href={cleanedPath} />
          </>
        )}

        <meta property="og:type" content={type || 'website'} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@wave_video" />

        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta name="google-site-verification" content="hk8J_7_ECOaQHTshxD-UJMmRxGoq5MTvJR72Q_Mf_Sw" />

        {preview && (
          <>
            <meta name="twitter:image" content={preview} />
            <meta property="og:image" content={preview} />
          </>
        )}

        {size && (
          <>
            <meta property="og:image:width" content={`${size.width}`} />
            <meta property="og:image:height" content={`${size.height}`} />
          </>
        )}

        {description && (
          <>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            <meta name="twitter:description" content={description} />
          </>
        )}
      </SafeHead>
      <Termly />
      {path && <ReviewSnippet pageName={getUrlShortPath(path)} />}
    </>
  );
};

export default withAppMeta(MetaHead);
